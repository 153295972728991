<template>
  <div class="flk-summary">
    <slot name="leftIcon" />
    <div class="flex justify-between w-full wrapSum">
      <div class="w-full leftSum">
        <div class="topSummary">
          <slot name="topSummary" />
        </div>
        <div class="bottomSummary">
          <slot name="bottomSummary" />
        </div>
      </div>
      <div class="flex items-center rightSummary">
        <slot name="rightIcon" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.topSummary {
  @apply font-medium leading-[18px] text-xs;
}
.flk-summary {
  @apply py-2.5 pl-2 pr-2 flex justify-start gap-4 items-center align-middle;
  @apply text-xs font-normal leading-[18px] mb-2 cursor-pointer;
}
</style>
